
import styles from './footer.module.scss';
import imgSrc from './li-small.png';

const Footer = () => {

  return (  
    <footer className={styles["footer"]}>
      <section>
        <a href="https://www.linkedin.com/in/kmunkvold/" target="_blank" rel="noreferrer" className={styles['image-link']}>
          <img src={imgSrc} height="32px" alt="linkedin" />      
          <span className={styles['link']}>See my profile on LinkedIn</span>
        </a>
      </section>      
      <section>
        <p>For questions, call <a href="tel:+4792607345" className={styles['link']}>+47 926 07 345</a></p>
      </section>
    </footer>
  );
}

export default Footer;
