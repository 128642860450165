import { useState } from 'react';
import styles from './header.module.scss';

// import delay from './utils/delay';

const Header = () => {
  const [toggleAnimation, setToggleAnimation] = useState(true);
  return (  
    <header className={styles['header']}>
      <h1 className={`${styles['logo' ]} && ${toggleAnimation && styles['animation']}`} onClick={() => { setToggleAnimation(!toggleAnimation)}}>MUNK</h1>
    </header>
  );
}

export default Header;
