import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './application';

const init = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// @ts-expect-error fonts is not typed
if (document && document.fonts) {    
  // @ts-expect-error fonts is not typed
  document.fonts.ready.then(() => {
    init()
  })
} else {
  init()
}

