import './munk.scss';

import Header from './header';
import Main from './main';
import Footer from './footer';


function App() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default App;
