import { useState } from 'react';
import styles from './main.module.scss';
import Typist from "react-typist";

const Main = () => {

  const [showCta, setShowCta] = useState(false);

  return (  
    <main className={styles['main']}>
      <h2 className={styles['header']}>Kristian S. Munkvold</h2>
      <section className={styles['text-animation']}>
        <Typist className={styles['lead']} startDelay={500} cursor={{hideWhenDone: true, blink: true}} onTypingDone={() => setTimeout(() => setShowCta(true), 250)} >
          Tech Lead
          <Typist.Backspace count={21} delay={500} />
          Design System Specialist
          <Typist.Backspace count={24} delay={500} />
          Web Applications
          <Typist.Backspace count={12} delay={500} />
          Platforms
          <Typist.Backspace count={16} delay={500} />
          Cloud Solutions
          <Typist.Backspace count={15} delay={500} />
          Developer Operations
          <Typist.Backspace count={20} delay={500} />
          Technology and Design Consultant
        </Typist>
      </section>
      <a className={`${styles['cta-button']} ${showCta && styles['active']}`} href="mailto:kristian@munk.io?subject=I'm interested in your profile">Get in touch</a>
    </main>
  );
}

export default Main;


